<template>
  <div class="lead-intake-container">
    <!-- <div v-show="!leadGlobalContainerLoader">  -->
    <hb-blade-header :title="location=='from_contact_view' || location=='move_in' ? 'Lead Follow Up' : 'Tenant Onboarding Process'" title-icon="mdi-account-box" :back-button-off="true"
      @close="close"></hb-blade-header>
      <v-row justify="center" align="center" v-if="leadGlobalContainerLoader">
      <v-col class="text-center ma-12">
        <v-progress-circular width="2" size="32" indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
    <div class="lead-intake-top" v-show="!leadGlobalContainerLoader && !displaySendQuoteModal">
      <div class="lead-intake-left">

        <div style="flex: 1; overflow: auto; background: #F9FAFB;">
        <div class = "mini-profile-reset-button-view mt-6 px-6" v-if="contact?.id">
          <mini-profile-view 
            style="max-width:80%"
            v-if="contact?.id"
            :contact-id="contact.id"
            :use-contact="false"
            :contact-data="contact"
          />
        <hb-link v-if="completedStep == 0 && !!contact?.id"  @click="resetForm"> Reset Form </hb-link>

        </div>
          <hb-tabs  v-model="currentLeadTab"  >
            <v-tab v-if="!selectedLeadId && !lead.id && stepper === 1" >
              Create New Lead &nbsp; 
            </v-tab>
              <v-tab v-if="lead.status == 'active'"  v-for="(lead, index) in latestContactData.Leads" :key="lead.id" @click="leadTabSwitch(lead)">
                <HbSpaceIcon
                                  small
                                  :type="lead?.Unit?.unit_type"
                                  class="mr-1"
                                  disable-small-size-position-fix
                              /> {{ lead.lead_type }}&nbsp; 
                <hb-tooltip>
                      <template v-slot:item>
                        <hb-icon small mdi-code="mdi-information"></hb-icon>
                      </template>
                      <template v-slot:body>
                        <div class="tooltip-container">
                         
                          {{ lead?.Unit?.number ? '#' + lead?.Unit?.number + ' | ' : ''  }} 
                          {{ lead?.Unit?.label ? lead?.Unit?.label + ' | ' : '' }} 
                          {{ lead?.Property?.property_details?.features_and_amenities_unit?.length > 0 ? lead?.Property?.property_details?.features_and_amenities_unit[0] +' | '+lead?.Property?.property_details?.features_and_amenities_unit[1] +' | '+lead?.Property?.property_details?.features_and_amenities_unit[2] : lead?.Property?.property_details?.features_and_amenities_property[0] + ' | ' +lead?.Property?.property_details?.features_and_amenities_property[1] + ' | ' +lead?.Property?.property_details?.features_and_amenities_property[2] }} | </br>
                          {{ lead?.Property?.number + ' - ' + lead?.Property?.property_details?.property_address?.city + ' - ' + lead?.Property?.property_details?.property_address?.address }}
                        </div>
                      
                      </template>
                </hb-tooltip>
              </v-tab>
          </hb-tabs>
          <!-- <v-divider  v-if="contact?.id && (stepper === 1 || stepper === 2)" style="width: 96.5%; float: inline-end; margin-top:10px"></v-divider> -->
          <v-divider  v-if="contact?.id && (stepper === 1 || stepper === 2)" ></v-divider>
          
          <hb-stepper v-model="stepper" height="72px">
            <hb-stepper-header :force-resize="resizeStepper">
              <hb-stepper-step  class="py-3" @click="goToStep(1)" step="1" :complete="completed1" :rules="[() => hasNoErrors1]">
                Lead
              </hb-stepper-step>

              <v-divider style="margin-top: 24px !important"></v-divider>

              <hb-stepper-step class="py-3" @click="goToStep(2)" step="2" :complete="completed2" :rules="[() => hasNoErrors2]">
                Lease
              </hb-stepper-step>

              <v-divider style="margin-top: 24px !important"></v-divider>

              <hb-stepper-step class="py-3" @click="goToStep(3)" step="3" :complete="completed3" :rules="[() => hasNoErrors3]">
                Payment
              </hb-stepper-step>

              <v-divider style="margin-top: 24px !important"></v-divider>

              <hb-stepper-step class="py-3" @click="goToStep(4)" step="4" :complete="completed4" :rules="[() => hasNoErrors4]">
                Finalize
              </hb-stepper-step>
            </hb-stepper-header>
        </hb-stepper>
          <!-- <v-tabs-items v-model="currentLeadTab">
            <v-tab-item  v-for="(lead, index) in contact.Leads" :key="lead.id" class="tab-items" > 
              {{ lead }}
            </v-tab-item>
          </v-tabs-items> -->
        
          <v-divider  v-if="contact?.id && (stepper === 1 || stepper === 2)" style="width: 96.5%; float: inline-end; margin-top:10px"></v-divider>
          <v-row v-if="showMultiSpaceRentalBanner">
            <v-col class="ml-5 mr-7 mb-0 pb-0">
              <div class="mt-2">
                <hb-notification 
                    type="caution"
                    not-dismissable
                    title-off
                >
                 {{ showMultiSpaceRentalBanner }}
                </hb-notification>
              </div>
            </v-col>
          </v-row>
          <lead ref="leadComponentRef" v-if="stepper === 1" :loading.sync="loadingLead" :showSpacesList="showSpacesList"
            :unKnownNumber="unKnownNumber" :charm_property_id="charm_property_id" :location="location" :disableLeadQuestions="disableLeadQuestions"
            :show_reminder="showReminder" @found="showFoundOptions" @contact_saved="setContactInfo" @close="close"
            @retire_lead="updateRetireLeadStatus" @isConfirmContactDialogVisible="setContactDialogStatus" :key="forceReloadLead" :loadLeadScreen="loadLeadScreen" :selectedLeadId="selectedLeadId" @showFoundLoader="showFoundLoader"></lead>
          <lease ref="leaseComponentRef" :loading.sync="loadingLease" @saved="leaseSaved" @hours-confirmed="productsSaved"
            @close="close" @accessData="setAccessData" @retire_lead="updateRetireLeadStatus" contact_type="lease" :refreshData="refreshLeaseData"
            v-if="stepper === 2">
          </lease>
          <payments ref="paymentsComponentReference" :loading.sync="loadingPayment" @saved="paymentsSaved" 
            @close="close" v-if="stepper === 3" @retire_lead="updateRetireLeadStatus"
            @payment_session_complete="handlePaymentSessionComplete"></payments>

          <div v-if="stepper === 4">
            <checklist :loading.sync="loading" @saved="finalize" @close="close"
              :accessCode="accessCode && accessCode.access_code"></checklist>
          </div>
          <div v-if="stepper==5">
            <receipt :loading.sync="loading" :payment_id="payment.id" @close="close"></receipt>
          </div>
        </div>
      </div>
      <div class="lead-intake-right">
        <hb-tabs v-model="activeTab" :background-color="'#FFFFFF'" height="74px" >
          <v-tab :ripple="false" @click="currentTab = 'units'" v-show="stepper < 4"> Spaces </v-tab>
          <v-tab-item class="tab-items" :class="{'add-padding-overlay-active': configuration_unit && configuration_unit.id}">
            <unit v-if="!configuration_unit || !configuration_unit.id" ref="unitsComponentRef" :unit_id="unit_id"
              @close="close" @setUnit="setUnit" v-show="currentTab === 'units'"></unit>
            <lease-info v-if="configuration_unit && configuration_unit.id"
              :unitComponentStatusFlag="unitComponentStatusFlag" ref="leaseInfoComponentReference" @goBack="unsetUnit"
              @saved="leaseConfigured" @close="close" @close_then_go_to_reservation="confirmCloseThenGoToReservation"
              @setOverlayAndSendLinkButtonFlag="setOverlayAndSendLinkButtonFlag"  @enableUpdateReservation="enableUpdateReservation" :key="forceReloadLeaseInfo"
              v-show="currentTab === 'units'" :apwPlans="apwPlans" ></lease-info>
          </v-tab-item>
          <v-tab :ripple="false" @click="currentTab = 'services'" v-show="stepper > 1 && stepper <= 3"> Services </v-tab>
          <v-tab-item class="tab-items" :class="{'add-padding-overlay-active': configuration_unit && configuration_unit.id}">
            <services ref="servicesComponentRef" :loading.sync="loading" @close="close"
              v-if="currentTab === 'services'"></services>
          </v-tab-item>
          <v-tab :ripple="false" @click="currentTab = 'communication'" v-show="stepper > 1 || contact?.id">
            Communication
          </v-tab>
          <v-tab-item class="tab-items" >
            <ChatWindow dense ref="communicationComponentRef" noHeader chatWindowHeight="large" :InteractionObj="contact"
              :from="interactionContext" v-if="currentTab === 'communication' && contact?.id"></ChatWindow>
          </v-tab-item>
          <v-tab :ripple="false" @click="currentTab = 'tenant_profile'" v-if="!contact.id">
            <!-- Tenant Profile -->
            Similar Contacts
          </v-tab>
          <v-tab-item class="tab-items" >
            <found ref="foundComponentRef" :contacts="foundContacts" @selected="setSelectedContact"
              v-if="currentTab === 'tenant_profile' && stepper == 1" :isFoundLoading="isFoundLoading"></found>
            <!-- <contacts-overview ref="tenantProfileComponentRef" :openPanel="lead.id" :contact="contact" readonly hideActionButtons
              v-if="currentTab === 'tenant_profile' && (stepper > 1 || contact?.id)"></contacts-overview> -->

            <!-- <lease-summary v-if="lease.id" :loading.sync="loading" :step="stepper" :gateAccessCode="accessCode" :key="updateLeaseSummery"></lease-summary> -->

          </v-tab-item>
        </hb-tabs>
        <lease-total-overlay v-if="configuration_unit && configuration_unit.id && showLinkButtonsAndOverlay &&(currentTab === 'units' || currentTab === 'services') " class="lease-total-overlay"
          :step="stepper"></lease-total-overlay>
      </div>
    </div>
    <send-quote v-if="displaySendQuoteModal" @openorClosetheSendQuote="openorClosetheSendQuote"
      :moveInUnitId="configuration_unit?.id"></send-quote>
    <retire-lead v-model="show_retire_lead" v-if="show_retire_lead && lead?.id" @retired="$emit('close')"
      :contact="latestContactData" :lead_id="lead?.id" :is_retired_reservation="lead?.is_reservation_lead == 1 && configuration_unit.id && configuration_unit.state == 'Reserved'" :isRetireBulk="showRetireLeadInteractions"></retire-lead>
    <hb-modal v-model="close_confirm" v-if="close_confirm" size="medium" title="Incomplete Lease Set Up" confirmation
      show-help-link>
      <template v-slot:content>
        <div class="py-6 px-6">
          This lease has not been completed. Would you like to delete it?
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn class="mr-2" color="primary" @click="confirmClose">Save as Pending</hb-btn>
        <hb-btn color="destructive" @click="deleteLease">Delete This Lease</hb-btn>
      </template>
    </hb-modal>
    <!-- BEHOLD THE BUTTONS -->
    <hb-bottom-action-bar @close="close"  v-show="!displaySendQuoteModal">
      <template v-slot:right-actions  v-if="!leadGlobalContainerLoader && stepper < 3">
        <hb-btn v-if="contact && contact?.id && activeLeadsList.length > 1" color="secondary" @click="updateRetireLeadStatus">Retire All Leads</hb-btn>
        <hb-btn v-if="contact && contact?.id && lead?.id && contact.status != 'Retired Lead' && hasPermission('retire_lead')" color="secondary"  @click="updateRetireLeadStatus" :disabled="allButtonsDisabled">
          {{ lead?.is_reservation_lead == 1 && configuration_unit.id && configuration_unit.state == 'Reserved' ? retireCancelLeadReservation[1] : retireCancelLeadReservation[0] }} </hb-btn>
        <span v-show="stepper == 3">
          <!-- Payment btnz -->


        </span>
        <span v-show="stepper <= 2">
          <hb-menu v-if="configuration_unit.id && showLinkButtonsAndOverlay" disableDFlex>
            <template v-slot:menu-activator>
                <hb-btn
                  
                  color="secondary"
                  append-icon="mdi-menu-down"
                  :loading="isLoading($options.name)"
                >
                  Send a Link
                </hb-btn>
            </template>
            <v-list>
                <v-list-item  v-show="configuration_unit.id && showLinkButtonsAndOverlay && configuration_unit.state !== 'Remove Overlock' && configuration_unit.state !== 'To Overlock' && configuration_unit.state !== 'Overlock'" @click="showRentalLinkDialog">
                  <v-list-item-title>Send Rental Link</v-list-item-title>
                  <CommonDialog :showLinkSenderDialog="showLinkRentalDialog"
                    :title="'Confirm'" :content="'How do you want to send the online rental link?'" :linkType="'rental'"
                    @closeLinkSender="closeCommonDialogPopUp" @close="commonDialogClose" :form="getLeadDataFromApi">
                  </CommonDialog>
                </v-list-item>
                <v-list-item v-show="configuration_unit.id && configuration_unit.state == 'Available' && showLinkButtonsAndOverlay && stepper == 1"  @click="showReservationDialog">
                  <v-list-item-title>Send Reservation Link</v-list-item-title>
                  <CommonDialog :showLinkSenderDialog="showLinkReservationDialog" :title="'Confirm'" :content="'How do you want to send the online reservation link?'" :linkType="'reservation'" @closeLinkSender="closeCommonDialogPopUp" @close="commonDialogClose"></CommonDialog>
                </v-list-item>
            </v-list>
          </hb-menu>
          <hb-btn v-show="stepper == 1" color="secondary" @click="showCreateFollowUoTask" :disabled="allButtonsDisabled"> Create Follow-Up Task </hb-btn>
          <!-- <hb-btn color="secondary" v-show="configuration_unit.id && configuration_unit.state == 'Available' && showLinkButtonsAndOverlay && stepper == 1" @click="showReservationDialog" :disabled="allButtonsDisabled" :loading="buttonLoading.sendReservationLinkButton"> Send Reservation Link <CommonDialog :showLinkSenderDialog="showLinkReservationDialog" :title="'Confirm'" :content="'How do you want to send the online reservation link?'" :linkType="'reservation'" @closeLinkSender="closeCommonDialogPopUp" @close="commonDialogClose"></CommonDialog> </hb-btn>
          <hb-btn color="secondary" v-show="configuration_unit.id && showLinkButtonsAndOverlay && configuration_unit.state !== 'Remove Overlock' && configuration_unit.state !== 'To Overlock' && configuration_unit.state !== 'Overlock'" :disabled="allButtonsDisabled" :loading="buttonLoading.sendRentalLinkButton"
            @click="showRentalLinkDialog"> Send Rental Link <CommonDialog :showLinkSenderDialog="showLinkRentalDialog"
              :title="'Confirm'" :content="'How do you want to send the online rental link?'" :linkType="'rental'"
              @closeLinkSender="closeCommonDialogPopUp" @close="commonDialogClose" :form="getLeadDataFromApi"></CommonDialog> </hb-btn> -->
        </span>
        <span v-show="stepper == 2">
          <hb-btn :loading="buttonLoading.paymentsButton" :disabled="allButtonsDisabled" @click="productsSaved">
            Payments
          </hb-btn>
        </span>
        <span v-show="stepper==1">

          <hb-btn color="secondary" v-show="configuration_unit?.state && configuration_unit.state != 'Available' && !showLinkButtonsAndOverlay"
            @click="createJoinWaitList" :loading="buttonLoading.joinWaitListInButton" :disabled="allButtonsDisabled">Join Waitlist</hb-btn>
          <hb-btn color="secondary"
            v-if="getSettings?.nationalAccountFeature == '1'"
            :disabled="allButtonsDisabled"
            :loading="buttonLoading.sendQuoteButton"
            @click="openorClosetheSendQuote">Send Quote</hb-btn>
          <!-- <hb-btn color="secondary" v-show="configuration_unit?.state && configuration_unit.state != 'Available'" @click="createJoinWaitList" :disabled="joinWaitListInProgress" >Send Quote</hb-btn> -->
          <hb-btn color="primary" @click="saveLead" v-show="!lead?.id || contact.status == 'Retired Lead'"
            :disabled="allButtonsDisabled" :loading="buttonLoading.saveLeadButton"> Save Lead </hb-btn>
            
            <hb-btn color="primary" v-if="configuration_unit?.state == 'Reserved' && (lead.lead_type === 'New Reservation' || lead.lead_type === 'New Web Reservation') && showLinkButtonsAndOverlay && enableUpdateReservationButton"
            @click="updateReservation" :disabled="allButtonsDisabled" :loading="buttonLoading.updateReservationButton"
            >
              Update Reservation
            </hb-btn>
          <hb-btn color="primary"
            v-else-if="configuration_unit.id && configuration_unit.state == 'Available'"
            :disabled="allButtonsDisabled"
            :loading="buttonLoading.reservationButton"
            @click="reserve"> Reserve </hb-btn>

          <hb-btn
            v-if="configuration_unit.id && showLinkButtonsAndOverlay && (configuration_unit.state == 'Available' || configuration_unit.state == 'Reserved')"
            color="primary" :disabled="allButtonsDisabled" :loading="buttonLoading.saveLeaseButton" @click="saveLease">Move In</hb-btn>

          <hb-btn
            v-else-if="configuration_unit.id && showLinkButtonsAndOverlay && (configuration_unit.state == 'Available' || configuration_unit.state == 'Reserved' || configuration_unit.state == 'Pending')"
            color="primary" :disabled="allButtonsDisabled" :loading="buttonLoading.savingLeaseButton" @click="saveLease">Next</hb-btn>

        </span>
      </template>
    </hb-bottom-action-bar>
    <hb-bottom-action-bar @close="openorClosetheSendQuote" v-show="displaySendQuoteModal">
      <template v-slot:right-actions>
        <span>
          <hb-btn color="secondary" @click="handleViewPrintRenatalQuote" :disabled="(sendQuotePropertyUnits?.length > 0)?false:true" :loading="buttonLoading.viewRentalQuoteButton">View / Print</hb-btn>
          <hb-btn color="secondary"  @click="showRentalQuoteDialog" :disabled="(sendQuotePropertyUnits?.length > 0)?false:true" :loading="buttonLoading.sendRentalQuoteButton"> Send Quote</hb-btn>
        </span>
      </template>
    </hb-bottom-action-bar>
    <hb-modal size="medium" title="Warning" v-model="showSelectExistingContactWarningDialog" confirmation show-help-link :footer-cancel-option="false">
      <template v-slot:content>
        <div class="py-4 px-6">
          A contact with this phone number and email address already exists. Please select the existing contact or change the email address or Phone number to create a new contact.
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="doNotProceed()">Ok</hb-btn>
      </template>
    </hb-modal>
    <CommonDialog :showLinkSenderDialog="showSendRentalQuoteDialog" :title="'Send Quote'"
      :content="'How would you want to send the quote?'" :linkType="'send_quote'"
      :fileData="sendQuotePDFFile"
      :sendQuotePropertyId="lead?.Property?.id"
      @redirectPage="redirectLeadSection"
      :sendQuoteEmailData="sendQuoteEmailData"
      :lead_id="this.lead?.id"
      @closeLinkSender="closeCommonDialogPopUp" @close="commonDialogClose">
    </CommonDialog> 

  </div>
</template>

<script>
import { EventBus } from "../../../EventBus.js";
import Lead from "./Lead.vue";
import Lease from "./Lease.vue";
import Payments from "./Payments.vue";
import Services from "./Services.vue";
import Checklist from './Checklist.vue';
import Receipt from './Receipt.vue';

import LeaseInfo from "./LeaseInfo.vue";
import ChatWindow from "../../communication_center/ChatWindow.vue";
import Unit from "./Unit.vue";
import ContactsOverview from "../../contacts/ContactsOverview.vue";
import LeaseTotalOverlay from "./LeaseTotalOverlay.vue";
import Found from "./Found.vue";
import { mapGetters,mapActions } from "vuex";
import RetireLead from '../RetireLead.vue';
import { notificationMixin } from "../../../mixins/notificationMixin";
import CommonDialog from './commonDialog.vue';
import PropertyInfo from './PropertyInfo.vue';
import LeaseSummary from './LeaseSummary.vue';
import api from '../../../assets/api.js';
import MiniProfileView from "./MiniProfileView.vue";
import SendQuote from "./SendQuote.vue";
import { settingsMixin } from "@/mixins/settingsMixin.js";
import SETTINGS from "@/constants/settings.js"; 
import LEASE from '@/constants/lease.js';
import moment from 'moment';
import RetireLeadInteractions from "../../leads/RetireLeadInteractions.vue";


export default {
  name: "LeadIntake",
  mixins:[notificationMixin, settingsMixin],
  data: function () {
    return {
      currentLeadTab: 0,
      resizeStepper: 0,
      stepper: 1,
      hasNoErrors1: true,
      hasNoErrors2: true,
      hasNoErrors3: true,
      hasNoErrors4: true,
      hasNoErrors5: true,
      completed1: false,
      completed2: false,
      completed3: false,
      completed4: false,
      completed5: false,
      canNavigate: true,
      activeTab: 0,
      currentTab: "units",
      show_retire_lead: false,
      foundContacts: [],
      foundNames: [],
      foundEmails: [],
      foundPhones: [],
      unitComponentStatusFlag: false,
      propertyId: "",
      loadingLead: "",
      loadingLease: "",
      loadingUnit: "",
      loadingRetireLead: false,
      loading: "",
      accessCode: "",
      showReminder: false,
      stepTwoButtonFlag: false,
      interactionContext: 'moveIn',
      showLinkReservationDialog:false,
      showLinkRentalDialog:false,
      goToPaymentsStep:false,
      close_confirm: false,
      gps_payload:{},
      leadGlobalContainerLoader:false,
      completedStep:0,
      renderCommonDialog:false,
      showSelectExistingContactWarningDialog:false,
      disableAllButtons:false,
      updateLeaseComponent:0,
      updateLeaseSummery:0,
      buttonLoading: {
          sendRentalLinkButton: false,
          paymentsButton: false,
          sendReservationLinkButton: false,
          joinWaitListInButton: false,
          saveLeadButton: false,
          saveLeaseButton:false,
          reservationButton: false,
          sendQuoteButton: false,
          updateReservationButton:false,
          sendRentalQuoteButton: false,
          viewRentalQuoteButton: false,
        },
      allButtonsDisabled:false,
      loadingPayment:false,
      showLinkButtonsAndOverlay:true,
      disableLeadQuestions:false,
      forceReloadLead:false,
      forceReloadLeaseInfo:0,
      loadLeadScreen:false,
      isFoundLoading:false,
      getLeadDataFromApi:0,
      refreshLeaseData:false,
      displaySendQuoteModal:false,
      apwPlans:{},
      retireCancelLeadReservation:['Retire This Lead', 'Cancel Reservation'],
      quoteSummeryModel:false,
      showSendRentalQuoteDialog:false,
      sendQuotePDFFile: null,
      showRetireLeadInteractions: false,
      activeLeadsList:[],
      sendQuoteEmailData:[],
      enableUpdateReservationButton: false,
      };
  },
  props: [
    "showLeadIntake",
    "contact_id",
    "property_id",
    "unit_id",
    "location",
    "showSpacesList",
    "unKnownNumber",
    "charm_property_id",
    "selectedLeadId",
  ],
  components: {
    Lead,
    Lease,
    Payments,
    Services,
    LeaseInfo,
    Unit,
    ChatWindow,
    ContactsOverview,
    Found,
    Checklist,
    Receipt,
    LeaseTotalOverlay,
    RetireLead,
    CommonDialog,
    LeaseSummary,
    PropertyInfo,
    MiniProfileView,
    SendQuote,
    RetireLeadInteractions
  },
  async created() {
    this.leadGlobalContainerLoader = true; 
    if(this.selectedLeadId){
      let activeLeads = this.contact.Leads.filter(lead => lead.status === 'active');
      const leadIndex = activeLeads.findIndex(lead => lead.id === this.selectedLeadId );
      this.currentLeadTab = leadIndex !== -1 ? leadIndex : 0; // Default to the first tab if not found
      let selectedLeadData = this.contact.Leads.filter(item=> item.id == this.selectedLeadId)[0]
      this.$store.commit("onBoardingStore/setLead",selectedLeadData)
    }else if(!this.lead?.id){
        let selectedLeadData = {}
        this.$store.commit("onBoardingStore/setLead",selectedLeadData)
      
    }
    console.log("selected lead data ==============> ",this.selectedLeadData);
    console.log("set lead data ==============> ",this.lead);
    
    this.$store.dispatch('onBoardingStore/getContactLatestData',{contact_id:this.contact.id})
    EventBus.$on("FromUnitViewSetUnit",this.setConfigurationInfo);
    EventBus.$on("lead-follow-up-interaction",this.leadCommunicationFlow);
    await this.setUnitFromMap()

    if(this.location == "move_in" && this.contact.Reservations?.length > 0 && this.lead?.Reservation?.id){
      // building reservations obj for store in reservation case.
      this.disableLeadQuestions = true;
      let reservationObj = {}
      reservationObj = this.contact.Reservations.find(r => r.id === this.lead.Reservation.id);
      if(reservationObj){
        reservationObj.reservation_time = reservationObj?.created
        reservationObj.contact_id = this.contact?.id;
        reservationObj.lead_first = this.contact?.first;
        reservationObj.lead_last = this.contact?.last;
        reservationObj.unit_id = reservationObj?.Lease?.Unit.id;
        reservationObj.reservation_id = reservationObj.id;
        console.log("Reservation Object: ", reservationObj)
        // this.retireCancelLeadReservation = "Cancel Reservation"
        try {
          let setup = await api.get(this, api.UNITS + reservationObj.unit_id + '/lease-set-up', {
          reservation_id: reservationObj.reservation_id,
          start_date: reservationObj.start_date ? reservationObj.start_date : reservationObj.Lease?.start_date
          });
          reservationObj.Setup = setup.details;
        } catch(error) {
          this.showMessageNotification({description: error})
        }
        await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === reservationObj.Lease.Unit.property_id));
        await this.$store.dispatch('sendQuoteStore/getPropertyInfo', this.properties.find(p => p.id === reservationObj.Lease.Unit.property_id));
        await this.$store.dispatch('onBoardingStore/getReservation', {reservation:reservationObj, contact_id: this.contact.id});
      }
    }
    else if(!!this.lead?.unit_id && (this.location == "from_contact_view" || this.location == "move_in")){
      this.setUnit({unit_id:this.lead?.unit_id})
    }
    
    EventBus.$on("design-system-sidebar-toggled", this.forceStepperResize);
    if (this.lease.id) {
      await this.setConfigurationInfo();

      if (this.lease.status === 1) this.goToStep(2);
      else await this.$store.dispatch('onBoardingStore/getEstimate');
    }

    if (this.property.id) {
      await this.$store.dispatch('onBoardingStore/getTaxRate', { property_id: this.property_id });
    }

    if (this.property_id) {
      // set property in store
      this.property = this.properties.find(p => p.id === this.property_id);
    } else {
      if (this.properties.length === 1) {
        this.property = this.properties[0];
      }
    }
    //added by bct-property-id
    if (this.charm_property_id) {
      this.property = this.properties.find(p => p.id === this.charm_property_id);
    }
    this.validateStep()
    // this.goToStep(1);

    // await this.$store.dispatch('onBoardingStore/getTaxRate', {property_id: this.property_id});

    // await this.checkLeaseProgress();
    EventBus.$on('reset_lease_hb_model', this.openModel);
    this.leadGlobalContainerLoader = false;
  },
  destroyed() {
    this.unsetUnit();
    EventBus.$off("design-system-sidebar-toggled", this.forceStepperResize);
  },
  methods: {
    ...mapActions({
              updateLeaseStore: 'onBoardingStore/setLease',
              actionFollowUpStatus:'onBoardingStore/actionFollowUpStatus',
              addLeadInteractionData : 'onBoardingStore/addLeadInteractionData'
            }),
    forceStepperResize() {
      this.resizeStepper++;
    },
    async goToStep(step, containerReload = true) {
      this.leadGlobalContainerLoader = containerReload;
      // allow to move back to the previous step.
      // but not allow to go forward to the next step.
      // but allow to go forward if the have already finished the forward step.
      if (step > this.completedStep+1 || this.completedStep >= 4) {
      this.leadGlobalContainerLoader = false;
      return;
      }
      // when on finalize step or above don't go back
      if(this.stepper >= 4){
        this.leadGlobalContainerLoader = false;
        return
      }
      // validate the step to go to
      //  got to step and set active tabs here
      
      
      switch(step) {
        case 1:
         this.activeTab = 0;
         this.currentTab = "units";
         if(!(this.location == "move_in" && this.reservation.id)){
           this.forceReloadLeaseInfo++;
         }
         if(this.completedStep >= 1){
           this.completedStep = 0;
           this.completed1 = false
           this.completed2 = false
           this.completed3 = false
         }
        //  await this.setConfigurationInfo();
          // this.completed1 = false;
          break;
        case 2:
          if(!this.isValidLead || !this.configuration_unit.id){
            this.hasNoErrors1 = false;
            this.completed1 = false;
            this.completedStep = 0
            this.leadGlobalContainerLoader = false;
            return
          }
          else{
            this.completed1 = true;
            this.completed2 = false
            this.hasNoErrors1 = true;
            this.completedStep = 1;
          }
          this.activeTab = 1;
          this.currentTab = "services";
          // this.updateLeaseComponent+=1
          await this.$store.dispatch('onBoardingStore/getContactLatestData',{contact_id:this.contact.id})
          this.updateLeaseSummery+=1
          break;
          case 3:
            if(!this.isValidTenant){
              this.hasNoErrors2 = false;
              this.completed2 = false;
            }
            else{
              this.completed2 = true;
            }
            // this.activeTab = 1;
            // this.currentTab = "services";
            break;
          case 4:
            this.completed1 = this.completed2 = this.completed3 = true;
            this.completedStep = 3
            this.activeTab = 2;
            this.currentTab = "communication";
          break;
        }
        this.stepper = step;
      this.leadGlobalContainerLoader = false;
      // 

    },
    updateRetireLeadStatus() {
      this.show_retire_lead = true;
      this.showRetireLeadInteractions = (lead?.is_reservation_lead == 1 && configuration_unit.id && configuration_unit.state == 'Reserved')
    },
    showFoundOptions(found) {
      if(found.contacts.length){
        this.activeTab = 3;
        this.currentTab = "tenant_profile";
      }
      switch (found.type) {
        case "name":
          this.foundNames = [];
          this.foundNames = found.contacts;
          break;
        case "email":
          this.foundEmails = [];
          this.foundEmails = found.contacts;
          break;
        case "phone":
          this.foundPhones = [];
          this.foundPhones = found.contacts;
          break;
      }
      const combinedContacts = [...this.foundNames, ...this.foundEmails, ...this.foundPhones];
      const uniqueContacts = Array.from(new Set(combinedContacts.map(contact => contact.id)))
      .map(id => combinedContacts.find(contact => contact.id === id));

    this.foundContacts = uniqueContacts;
      // this.foundContacts = [...this.foundNames, ...this.foundEmails, ...this.foundPhones];
    },
    async setSelectedContact(contact) {
      this.loadLeadScreen = true;
      if (this.lease.id) {
        await api.post(this, api.LEASES + this.$store.getters.lease.id + "/tenants", {
          contact_id: contact.id,
          lead_id: this.lead?.id
        });
        await this.$store.dispatch("onBoardingStore/findLeaseTenants");
        this.$store.commit("onBoardingStore/setActiveContactId", contact.id);
      } else {
        await this.$store.dispatch("onBoardingStore/getContactInfo", {
          contact_id: contact.id,
          click_event: "useThisContact",
        });
      }

      this.forceReloadLead = !this.forceReloadLead
      this.loadLeadScreen = false;
      this.activeTab = 0;
      this.currentTab = "units";
    },
    close(force, contact_id = "") {
      switch (this.stepper) {
        case 1:
        this.confirmClose();
          break;
        case 2:
        this.close_confirm = true;
          break;
        case 3:
        this.close_confirm = true;
          break;
        case 4:
        this.close_confirm = true;
        break;
        case 5:
          this.confirmClose('',this.contact_id);
          break;
        }
        // In  case of showing confirmation dialog when on first step after completing it.
      //   if(this.completedStep > 1){
      //   this.close_confirm = true;
      // }
      // if(this.stepper == 2){
      //   this.close_confirm = true;
      // }
      // this.confirmClose();
    },
    confirmClose(thenOpenReservation, contact_id = "") {
      this.$store.commit("onBoardingStore/resetLease");
      this.$store.commit("onBoardingStore/resetContact");
      EventBus.$emit("unit_available_changed");
      EventBus.$emit("refresh-contact-profile");
      if('property_ids' in this.gps_payload && this.gps_payload.property_ids.length > 0) EventBus.$emit('reset_gps_bulk_property',this.gps_payload);
      else EventBus.$emit('reset_gps_property',this.gps_payload);
      this.$emit("close");
    },
    async setUnit(data) {
      this.loading = true;
      this.disableLeadQuestions = true;
      this.$store.commit("onBoardingStore/resetConfigurationLease");
      this.$store.commit("onBoardingStore/resetConfigurationInvoice");
      await this.$store.dispatch("onBoardingStore/getConfigurationUnitInfo", { unit_id: data.unit_id, });
      if(this.contact?.id){
        const params = {
          contact_id: this.contact?.id,
          property_id: this.property?.id,
          unit_type_id: this.configuration_unit?.unit_type_id
        }
        await this.$store.dispatch("onBoardingStore/updateSingleSpaceRental",params);
      }
      // this.summary_panel = "lease";
      this.loading = false;
    },
    unsetUnit() {
      this.loading = false;
      this.disableLeadQuestions = false;
      this.$store.commit("onBoardingStore/resetConfigurationUnit");
      this.$store.commit("onBoardingStore/resetUnitsInArea");
      // this.$store.commit('onBoardingStore/resetLease');
      this.$store.commit("onBoardingStore/resetInitialCharges");
      // this.summary_panel = "property";
    },
    confirmCloseThenGoToReservation(newReservationData) {
      this.$store.commit("onBoardingStore/resetLease");
      this.$store.commit("onBoardingStore/resetContact");
      // this.$emit("close_then_go_to_reservation", newReservationData);
      this.$emit("close")
      this.$router.push('/leads');
      EventBus.$emit("unit_available_changed");
      EventBus.$emit("refresh-contact-profile");
    },
    async leaseConfigured(lease) {
      this.loading = true;
      await this.$store.dispatch("onBoardingStore/getServices");
      // this.$store.commit('onBoardingStore/resetConfigurationUnit');
      this.$store.commit("onBoardingStore/resetInitialCharges");
      // this.step = 3;
      await this.$store.dispatch("onBoardingStore/getEstimate");
    },
    leaseSaved() {
      this.completedStep = 2
      // if (this.lease.id) {
      //   this.stepper = 3;
      // } else {
      //   this.stepper = 1;
      // }
    },
    setAccessData(value) {
      this.accessCode = value;
    },
    
    paymentsSaved() {
      
      this.loadingPayment = true;
      // this.completed3=true;
      this.completedStep = 3
      // this.stepper = 4;
      this.goToStep(4)
    },
    async saveLead(next = null, actionType) {
      if (actionType == "send_quote") {
        this.handleButtonClickStatus("sendQuoteButton", true)
      }else{
        this.handleButtonClickStatus("saveLeadButton",true)
      }
     
      let ContactExist;
      // validate form 
      let formMessage = await this.$refs.leadComponentRef.validateLeadForm()
      if(formMessage == "LEAD_FORM_ERROR") {
        if (actionType == "send_quote") {
          this.handleButtonClickStatus("sendQuoteButton", false)
        }
        this.handleButtonClickStatus("saveLeadButton",false)      
        return
      }
      // check if contact already exists and it's not already selected
      if(!this.contact?.id){
        ContactExist = await this.$refs.leadComponentRef.isContactExist();
        if(ContactExist){
          this.showSelectExistingContactWarningDialog = true;
          if (actionType == "send_quote") {
            this.handleButtonClickStatus("sendQuoteButton", false)
          }
          this.handleButtonClickStatus("saveLeadButton",false)
          return;
        }
      }
      try {
        await this.$refs.leadComponentRef.saveLead(null, null, actionType);
        this.handleButtonClickStatus("saveLeadButton",false)
        if (actionType == "send_quote") {
          this.handleButtonClickStatus("sendQuoteButton", false)
          this.displaySendQuoteModal = true
        }else{
          this.$router.push('/leads'); // save lead and go to leads section
        }
      } catch (error) {
        this.handleButtonClickStatus("saveLeadButton",false)
        if (actionType == "send_quote") {
          this.handleButtonClickStatus("sendQuoteButton", false)
        }
      }
    },
    async saveLease() {
      this.handleButtonClickStatus("saveLeaseButton",true)
      try {
        let ContactExist;
        // validate form
        let formMessage = await this.$refs.leadComponentRef.validateLeadForm()
        if(formMessage == "LEAD_FORM_ERROR") {
          this.handleButtonClickStatus("saveLeadButton",false)
          this.handleButtonClickStatus("saveLeaseButton",false)     
          return
        }
      // check if contact already exists and it's not already selected
      if(!this.contact?.id){
        ContactExist = await this.$refs.leadComponentRef.isContactExist();
        if(ContactExist){
          this.showSelectExistingContactWarningDialog = true;
          this.handleButtonClickStatus("saveLeaseButton",false)
          return;
        }
      }
      let saveLeadRes = await this.$refs.leadComponentRef.saveLead("SPECIAL");
        if(saveLeadRes == 'LEAD_FORM_ERROR') {
          this.handleButtonClickStatus("saveLeaseButton",false)
          return
        };
       let saveLeaseRes = await this.$refs.leaseInfoComponentReference.saveLease();
       if(saveLeaseRes == "LEASE_FORM_ERROR"){
        this.handleButtonClickStatus("saveLeaseButton",false)
        return;
       }
        // TODO move this to goToStep
        this.completedStep=1
          this.goToStep(2)
          // this.completed1 = true;
          // this.stepper = 2;
          // this.activeTab = 1;
          // this.currentTab = "services";
          this.handleButtonClickStatus("saveLeaseButton",false)
          this.$store.commit('onBoardingStore/setConfigurationUnit',this.unit)
          this.updateLeaseSummery+=1
        } catch (error) {
          this.handleButtonClickStatus("saveLeaseButton",false)
          this.stepper = 1;
        }
    },
    async productsSaved(){
      if (this.getTaxExempt && !this.getTaxExemptDescription) {
            this.showMessageNotification({ type: 'error', description: 'Please enter reason for tax exempt' });
            return;
      }
      this.handleButtonClickStatus("paymentsButton",true)
      try {
        let errorFlag = await this.$refs.leaseComponentRef.validateForm();
        // let errorFlag = await this.$refs.leaseComponentRef.saveTenant(false);
        if(errorFlag == "ERROR_SAVE_TENANT"){
          this.handleButtonClickStatus("paymentsButton",false)
          return ;
        }
        if(!this.canProgress){
          this.showMessageNotification({
            type: 'error', 
            title: 'Please select a coverage.',
            description: 'You need to select a coverage option to proceed to payments.',
          });
          this.handleButtonClickStatus("paymentsButton",false)
          return
        }
        errorFlag = await this.$refs.leaseComponentRef.saveTenant(false);
        if(errorFlag == "ERROR_SAVE_TENANT"){
          this.handleButtonClickStatus("paymentsButton",false)
          return ;
        }
                //  property saved didn't had validation 
        // await this.$refs.servicesComponentRef.productsSaved();
        this.handleButtonClickStatus("paymentsButton",false)
        this.goToStep(3)
      } catch (error) {
        console.log("====================================================== error during product save",error);
        this.handleButtonClickStatus("paymentsButton",false)
        return;
      }
     

    },
    async handlePaymentSessionComplete(data){

      if(data.status == "complete"){
        // this.completed3 = true;
        this.completedStep = 3
        this.goToStep(4)
      }
      else{
        this.completedStep = 2
        this.goToStep(3)
        // this.stepper = 3;
        // this.completed3 = false;
    }
    },
    async setContactInfo(data) {
      let actionType = data?.actionType;
      // if we are coming from a space, this should be set.
      // If we want to change the space, dont set reservation details
      this.loading = true;
      this.showSpacesList = false;
      if (!data.next) {
        if(!(actionType=="send_quote")){          
          this.confirmClose();
        }
        api.get(this, api.CONTACTS + data.contact_id).then(r => {
          var contact = r.contact;
          var data = {
            contact_id: contact.id,
            lead_first: contact.first,
            lead_last: contact.last
          }

          if (contact.Lead) {
            data.lead_time = contact.Lead.created;
            data.id = contact.Lead.id;
          }

          data.event = event;

          // EventBus.$emit('HB-Navigation:LeadFollowUp', data);
          if ((actionType == "send_quote")) {
            this.displaySendQuoteModal = true
          }else{
            this.confirmClose()
            this.$router.push('/leads');
          }
        });
        return;
      }
      // await this.setConfigurationInfo(data);
      // this.step = 2;
      //this.checkLeaseProgress();
      this.validateUnit();
    },
    async setConfigurationInfo(data = {}) {
      if (this.unit.id) {
        this.$store.commit('onBoardingStore/resetConfigurationLease');
        this.$store.commit('onBoardingStore/resetConfigurationInvoice');
        await this.$store.dispatch('onBoardingStore/getConfigurationUnitInfo', {
          unit_id: this.unit.id
        });

        if (data.next == 'change') {
          this.unsetUnit();
        }
        //  else {
        //   this.summary_panel = 'lease';
        // }


        //await this.$store.dispatch('onBoardingStore/buildDefaultLease');
      } else if (this.reservation.id) {
        if (data.next !== 'change') {
          this.$store.commit('onBoardingStore/setUnit', this.reservation.Unit);
          this.$store.commit('onBoardingStore/setTemplate', this.reservation.Template);
        }
        await this.$store.dispatch('onBoardingStore/setReservationLease');
      }
    },
    validateUnit() {

      let is_prop_same = this.property.id === this.configuration_unit.property_id;
      let is_prop_exist_in_selection = !!this.properties.find(property => property.id === this.configuration_unit.property_id);
      if (is_prop_same && is_prop_exist_in_selection) return true

      this.unsetUnit()
      return false
    },
    finalize(){
                this.completed4=true;
                this.completedStep=4
                // this.goToStep(5)
                this.stepper = 5;
                this.lease.status = 1;
                this.$store.commit('onBoardingStore/setConfigurationUnit',this.unit)
                this.updateLeaseStore(this.lease);
                this.updateLeaseSummery+=1
                // this.activeTab = 3;
                // this.currentTab = "tenant_profile";
    },
    async reserve(){
      this.handleButtonClickStatus("reservationButton",true)
      let ContactExist;
      // validate form
      let formMessage = await this.$refs.leadComponentRef.validateLeadForm()
      if(formMessage == "LEAD_FORM_ERROR") {
        this.handleButtonClickStatus("reservationButton",false)      
        return
      }
      // check if contact already exists and it's not already selected
      if(!this.contact?.id){
        ContactExist = await this.$refs.leadComponentRef.isContactExist();
        if(ContactExist){
          this.showSelectExistingContactWarningDialog = true;
          this.handleButtonClickStatus("reservationButton",false)
          return;
        }
      }
      let validationLead = await this.$refs.leadComponentRef.saveLead('space','reservation_flow');
      if(validationLead == "LEAD_FORM_ERROR"){
        this.handleButtonClickStatus("reservationButton",false)
        return;
      }
      let validationReserve = await this.$refs.leaseInfoComponentReference.reserve();
      if(validationReserve == "RESERVE_VALIDATION_ERROR"){
        this.handleButtonClickStatus("reservationButton",false)
      return;
    }
    this.handleButtonClickStatus("reservationButton",false)
    },
    showCreateFollowUoTask(){
      this.$refs.leadComponentRef.show_reminder = true;
    },
    async createJoinWaitList(){
      try{
        this.handleButtonClickStatus("joinWaitListInButton",true)
        let err = await this.$refs.leadComponentRef.createJoinWaitList({unitData:this.configuration_unit, leadData:this.lead});
        if (err=="LEAD_FORM_ERROR"){
          this.handleButtonClickStatus("joinWaitListInButton",false)
          return;
        }else{
          this.showMessageNotification({
            type: 'success', 
            title: 'Added to wait list',
            description: 'The lead has been added to the wait list'
          });
          this.handleButtonClickStatus("joinWaitListInButton",false)
          this.close();
      }
      }catch(e){
        this.handleButtonClickStatus("joinWaitListInButton",false)
        this.showMessageNotification({
          type: 'error', 
          title: 'Failed to add to wait list',
          description: e.message
        });
      }
    },
    async showReservationDialog(){
      this.handleButtonClickStatus("sendReservationLinkButton",true)
      let msg;
      let ContactExist;
      // validate the form
      msg = await this.$refs.leadComponentRef.validateLeadForm();
      // }
      if(msg == 'LEAD_FORM_ERROR') {
        this.handleButtonClickStatus("sendReservationLinkButton",false)
        return
      };
      // check if contact already exists and it's not already selected
      if(!this.contact?.id){
        ContactExist = await this.$refs.leadComponentRef.isContactExist();
        if(ContactExist){
          this.handleButtonClickStatus("sendReservationLinkButton",false)
          this.showSelectExistingContactWarningDialog = true;
          return;
        }
      }
      // if(!this.lead.id){
        msg = await this.$refs.leadComponentRef.validateLeadForm();
      // }
      if(msg == 'LEAD_FORM_ERROR') {
        this.handleButtonClickStatus("sendReservationLinkButton",false)
        return
      };
      await this.$refs.leadComponentRef.saveLead('space');
      // if(this.renderCommonDialog) return // when continuing without selecting existing contact.
      this.handleButtonClickStatus("sendReservationLinkButton",false)
      this.showLinkReservationDialog = true
    },
    async showRentalLinkDialog() {
      this.handleButtonClickStatus("sendRentalLinkButton", true)
      let ContactExist;
      if (this.stepper == 1) {
        let msg = await this.$refs.leadComponentRef.validateLeadForm();
        if (msg == 'LEAD_FORM_ERROR') {
          this.handleButtonClickStatus("sendRentalLinkButton", false)
          return

        };
      }
      // check if contact already exists and it's not already selected
      if (!this.contact?.id) {
        ContactExist = await this.$refs.leadComponentRef.isContactExist();
        if (ContactExist) {
          this.showSelectExistingContactWarningDialog = true;
          this.handleButtonClickStatus("sendRentalLinkButton", false)
          return;
        }
      }
      if (this.stepper == 1) {
        // let msg = await this.$refs.leadComponentRef.validateLeadForm();
        // if (msg == 'LEAD_FORM_ERROR') {
        //   this.handleButtonClickStatus("sendRentalLinkButton", false)
        //   return

        // };
        await this.$refs.leadComponentRef.saveLead('space');
        // if (this.renderCommonDialog) {
        //   this.handleButtonClickStatus("sendRentalLinkButton", false)
        //   return
        // }
        this.handleButtonClickStatus("sendRentalLinkButton", false)
        this.showLinkRentalDialog = true
      } else {
        try {
          let errorFlag = await this.$refs.leaseComponentRef.saveTenant(false);
          if (errorFlag == "ERROR_SAVE_TENANT") {
            this.handleButtonClickStatus("sendRentalLinkButton", false)
            return;
          }
          this.handleButtonClickStatus("sendRentalLinkButton", false)
          this.getLeadDataFromApi+=1
          this.showLinkRentalDialog = true
        } catch (error) {
          this.handleButtonClickStatus("sendRentalLinkButton", false)
        }
      }
    },
    async validateStep(reloadContainer = true){
      if(this.invoices && this.invoices.length && this.invoices[0].id && this.lease.status !== 1){ 
        this.completedStep = 3;
        this.goToStep(4)
        return;
      }
      // if (this.lease.id && this.lease.status && this.isValidTenant && (this.lease.decline_insurance || this.insurance.id)) {
      //   this.completedStep = 2
      //   this.goToStep(3)
      //   return;
      // }
      if(this.lease.id && this.reservation.id){
        this.goToStep(1);
        return
      }
      if(this.lease.id) {
        this.completedStep = 1;
        this.goToStep(2,reloadContainer)
        return;
      }
      this.completedStep = 0;
    },
    async deleteLease() {
      if (!this.lease.id) return;

      EventBus.$emit('unit_available_changed');

      try {
        await this.$store.dispatch('onBoardingStore/deletePending');
        this.$emit('close');
        // if we change property from GPS while move in, we need to change property after delete.
        if ('property_ids' in this.gps_payload && this.gps_payload.property_ids.length > 0) EventBus.$emit('reset_gps_bulk_property', this.gps_payload);
        else EventBus.$emit('reset_gps_property', this.gps_payload);
      } catch (err) {
        this.showMessageNotification({ description: `Lease deletion error: ${err}` });
        let currentLease = await api.get(this, api.LEASES + this.lease.id + '/status');
        if (currentLease.lease.status === 1) {
          this.$emit('close');
        }
      }

    },
    openModel(payload = {}) {
      this.gps_payload = payload;
      if (this.stepper == 1) {
        // this.goToStep(1);
        if ('property_ids' in this.gps_payload && this.gps_payload.property_ids.length > 0) EventBus.$emit('reset_gps_bulk_property', this.gps_payload);
        else EventBus.$emit('reset_gps_property', this.gps_payload);
      }
      else if (this.stepper >= 2 && this.stepper <= 4) this.close_confirm = true;
    },
    async setUnitFromMap(){
      if(this.unit.id){
        this.unsetUnit()
      await this.$store.dispatch('onBoardingStore/getConfigurationUnitInfo', { unit_id: this.unit.id });
      }
    },
    async leadCommunicationFlow(type){
      // open communication tab and set the type.
      let previousTab = this.currentTab;
      this.activeTab = 2;
      this.currentTab = "communication";
      // this if statement is needed until communication tab is loaded in dom.
      if(previousTab !== "communication"){
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
        switch(type) {
          case 'phone_log':
            this.$refs.communicationComponentRef.onPhoneShow()
            break;
          case 'email':
          this.$refs.communicationComponentRef.onEmailShow()
            break;
          case 'note':
          this.$refs.communicationComponentRef.onNoteShow()
          break;
          case 'text':
            this.$refs.communicationComponentRef.onSmsShow()
            break;
          default:
            break;
      };
    },
    setContactDialogStatus(data){
      this.renderCommonDialog = data.flag 
    },
    doNotProceed(){
      this.showSelectExistingContactWarningDialog = false;
    },
    closeCommonDialogPopUp(data){
      if(data.linkType == 'rental'){ 
        this.handleButtonClickStatus("sendRentalLinkButton",false)
        this.showLinkRentalDialog = false
      }
      else if (data.linkType == 'reservation') {
        this.handleButtonClickStatus("sendReservationLinkButton",false)
        this.showLinkReservationDialog = false
      }else if (data.linkType == 'send_quote') {
        this.handleButtonClickStatus("sendRentalQuoteButton",false)
        this.showSendRentalQuoteDialog = false
        return;
      }
      this.close()
    },
    commonDialogClose(data){
      if(data.linkType == 'rental'){ 
        this.handleButtonClickStatus("sendRentalLinkButton",false)
        this.showLinkRentalDialog = false
      }
      else if (data.linkType == 'reservation') {
        this.handleButtonClickStatus("sendReservationLinkButton",false)
        this.showLinkReservationDialog=false
      }else if (data.linkType == 'send_quote') {
        this.handleButtonClickStatus("sendRentalQuoteButton",false)
        this.showSendRentalQuoteDialog = false
        return;
      }
      // this.unsetUnit()
      // this.deleteInitialLease()
    },
    redirectLeadSection(data){
      if (data.linkType == 'send_quote') {
        this.$router.push('/leads'); // redirect lead section
        this.$emit("close");
      }
    },
    async deleteInitialLease() {
      if (this.lease.unit_id && this.configuration_unit.id) {
        await this.$store.dispatch('onBoardingStore/deleteLease', this.lease);
      }
    },
    async saveLeadUpdateAndGoToLease(){
      let ContactExist;
      // check if contact already exists and it's not already selected
      this.savingLeaseInProgress = true;
      if(!this.contact?.id){
        ContactExist = await this.$refs.leadComponentRef.isContactExist();
        if(ContactExist){
          this.showSelectExistingContactWarningDialog = true;
          return;
        }
      }
      try {
        await this.$refs.leadComponentRef.saveLead('space');
        this.goToStep(2);
        this.refreshLeaseData = !this.refreshLeaseData
        // this.updateLeaseComponent += 1
        this.savingLeaseInProgress = false;
      } catch (error) {
        this.savingLeaseInProgress = false;
      } finally {
        this.savingLeaseInProgress = false;
      }
    },
    handleButtonClickStatus(buttonName, status) {
    this.allButtonsDisabled = status;
    this.buttonLoading = { ...this.buttonLoading, [buttonName]: status };   
    },
    setOverlayAndSendLinkButtonFlag(flag){
      this.showLinkButtonsAndOverlay = flag
    },
    enableUpdateReservation(flag){
      this.enableUpdateReservationButton = flag;
    },
    resetForm(){
      this.addLeadInteractionData({})
      this.$store.commit("onBoardingStore/resetContact");
      this.$store.commit("onBoardingStore/resetTenants");
      this.$store.commit("onBoardingStore/resetLease");
      this.$store.commit("onBoardingStore/resetInvoice");
      this.$store.commit("onBoardingStore/resetReservation");
      this.$store.commit("onBoardingStore/resetUnit");
      this.$store.commit("onBoardingStore/resetProperty");
      this.$store.commit("onBoardingStore/resetConfigurationUnit");
      this.$store.commit("onBoardingStore/resetInitialCharges");
      this.$store.commit("onBoardingStore/setContactLatestData",{})
      // EventBus.$emit('resetForm')
      this.disableLeadQuestions = false
      this.forceReloadLead = !this.forceReloadLead
      this.foundContacts=[]
      setTimeout(() => {
        this.activeTab = 3
        this.currentTab = "tenant_profile"
      },200);
    },
    showFoundLoader(value){
      this.isFoundLoading = value.showFoundLoader;
    },
    async updateReservation(){
      this.handleButtonClickStatus("updateReservationButton",true)
      // perform validation 
      await this.$refs.leaseInfoComponentReference.updateReservation()
      this.handleButtonClickStatus("updateReservationButton",false)

    },
    async leadTabSwitch(lead){
      // reset stepper??
      await this.$store.commit("onBoardingStore/resetInitialCharges");
      await this.$store.commit("onBoardingStore/resetInvoice");
      await this.$store.commit("onBoardingStore/resetConfigurationUnit");
      await this.$store.commit("onBoardingStore/resetReservation");
      await this.$store.commit("onBoardingStore/resetUnit");
      await this.$store.commit("onBoardingStore/resetProperty");
      await this.$store.commit("onBoardingStore/resetLease");

      this.completedStep = 0;
      this.completed1 = false
      this.completed2 = false
      this.completed3 = false
      this.stepper = 1;
      // this.goToStep(1);
      // let id = lead?.id
      // this.unsetUnit()
      // await this.$store.commit("onBoardingStore/resetLease");
      await this.$store.commit("onBoardingStore/setLead",lead)
      if(this.latestContactData.Reservations?.length > 0 && this.lead?.Reservation?.id){
      // building reservations obj for store in reservation case.
      this.disableLeadQuestions = true;
      let reservationObj = {}
      reservationObj = this.latestContactData.Reservations.find(r => r.id === this.lead.Reservation.id);
        if(reservationObj){
          reservationObj.reservation_time = reservationObj?.created
          reservationObj.contact_id = this.contact?.id;
          reservationObj.lead_first = this.contact?.first;
          reservationObj.lead_last = this.contact?.last;
          reservationObj.unit_id = reservationObj?.Lease?.Unit.id;
          reservationObj.reservation_id = reservationObj.id;
          console.log("Reservation Object: ", reservationObj)
          // this.retireCancelLeadReservation = "Cancel Reservation"
          try {
            let setup = await api.get(this, api.UNITS + reservationObj.unit_id + '/lease-set-up', {
            reservation_id: reservationObj.reservation_id,
            start_date: reservationObj.start_date ? reservationObj.start_date : reservationObj.Lease?.start_date
            });
            reservationObj.Setup = setup.details;
          } catch(error) {
            this.showMessageNotification({description: error})
          }
          await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === reservationObj.Lease.Unit.property_id));
          await this.$store.dispatch('onBoardingStore/getReservation', {reservation:reservationObj, contact_id: this.contact.id});
        }
      }
      else{
        // set lease or something??
        if(this.lead?.Lease?.status == 2){
          this.completedStep = 0;
          this.completed1 = true
          await this.$store.dispatch('onBoardingStore/getPending', { unit_id: this.lead.unit_id });
        }
        
        
      }
     if(!!this.lead?.unit_id){
      await this.setUnit({unit_id:this.lead?.unit_id})
      }
    
      let selectedLeadLease = this.latestContactData.Leads.find(lead => lead.id == this.lead.id)
      this.property = this.properties.find(p => p.id === (selectedLeadLease?.Lease?.Unit?.Property?.id ?? selectedLeadLease?.Property?.id )  );
      // await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.property))
      if(this.lead?.Lease?.status == 2)
        await this.$store.dispatch('onBoardingStore/getPending', { unit_id: this.lead.unit_id });
        
      // this.updateLeaseStore(this.lease);
      await this.setConfigurationInfo();
      
      if (this.lease.status === 2) this.goToStep(2,false);
      else await this.$store.dispatch('onBoardingStore/getEstimate');
      await this.validateStep(false)
    },
    async openorClosetheSendQuote() {
      if (this.displaySendQuoteModal) {
        this.displaySendQuoteModal = !this.displaySendQuoteModal;
      } else {
        await this.$store.dispatch('sendQuoteStore/getPropertyInfo', this.properties.find(p => p.id == this.property?.id));
        await this.saveLead(null, 'send_quote')
      }

    },
    async handleViewPrintRenatalQuote() {
      try {
        this.handleButtonClickStatus("viewRentalQuoteButton",true)
        // this.downloadButton = true;
        const blobUrl = await this.generateRenatalQuotePDF();
        var link = document.createElement("a");
        link.href = blobUrl;
        window.open(link.href, "_blank");
      } catch (error) {
        this.handleButtonClickStatus("viewRentalQuoteButton",false);
        console.error("Error during generate renatal quote:", error);
      } finally {
        this.handleButtonClickStatus("viewRentalQuoteButton",false);
      }
    },
    async generateRenatalQuotePDF() {
      let cleanedPropUnitObject = await this.prepareSendQuotePDFGenObj();
      let tenantAddresses = (this.contact?.Addresses?.length>0)?this.contact.Addresses[0].Address:null
      
      let tenantInfo = {
        fullName: [this.contact.first, this.contact.middle, this.contact.last].filter(Boolean).join(" "),
        address: tenantAddresses
      }
      const format = "pdf";
      const timeZone = moment.tz.guess();
      const requestData = {
        type: "send-quote-summary",
        format,
        data: {
          tenantInfo: tenantInfo,
          propertyUnits: cleanedPropUnitObject
        },
        contact_id: this.contact?.id,
        timeZone,
        name: "Send Quote Summary"
      };
      try {
        const response = await api.post(this, `${api.REPORTS}download-pdf`, requestData);
        // Convert response data to Blob
        const buffer = new Uint8Array(response.data);
        const blob = new Blob([buffer], { type: "application/pdf" });
        return window.URL.createObjectURL(blob);
      } catch (err) {
        this.handleButtonClickStatus("viewRentalQuoteButton",false);
        console.error("Error fetching the renatal quote pdf:", err);
        throw err; // Re-throw to handle in `handleViewPrintRenatalQuote`
      }
    },
    async prepareSendQuotePDFGenObj() {
      // Check if there are property units selected
      if (this.sendQuotePropertyUnits?.length > 0) {
        try {
          let parsedObj = JSON.parse(JSON.stringify(this.sendQuotePropertyUnits));

          // Process each property data asynchronously
          parsedObj = await Promise.all(
            parsedObj.map(async (propData) => {
              // Initialize unitTotalCost and serviceItems array for each property
              let propertyTotalCost = 0;


              // Process each unit under the property
              const unitValues = await Promise.all(
                propData?.units.map(async (unitValue) => {
                  let unitTotalCost = 0;
                  let serviceItems = [];
                  let unit_amenities = (unitValue?.unit_amenities)
                    ? await this.getFirstTwoAmenities(unitValue.unit_amenities)
                    : '';  // Get first two amenities if available

                  let unit_type_name = (unitValue?.unit_type)
                    ? await this.formatUnitTypeString(unitValue.unit_type)
                    : '';  // Format unit type string if available

                  // Initialize service items array for the unit
                  let serviceItemsUnit = [{
                    name: unitValue?.unit_number,
                    price: unitValue?.unit_price,
                    qty: 1,
                    description: unitValue?.unit_number,
                    taxable: 0,
                    product_id: unitValue?.unit_id,
                  }];

                  // Set default tax rate for unit
                  let unitTaxRate = 0;
                  if (propData?.propertyTax?.length > 0) {
                    // Find the tax rate based on the unit type
                    const taxItem = await propData.propertyTax.find(item => item?.unit_type_id == unitValue?.unit_type_id);
                    taxItem
                    unitTaxRate = taxItem?.tax_rate || 0;
                  }

                  // Call unitServiceLineItem to get the total cost including GST for the unit
                  let unitServiceRes = await this.unitServiceLineItem(serviceItemsUnit, unitTaxRate, 'unit');
                  unitTotalCost = unitTotalCost + (unitServiceRes?.totalServiceCostIncludingGST || 0);  // Add the service cost to the grand total

                  // Concatenate the service items returned from unitServiceLineItem
                  serviceItems = await serviceItems.concat(unitServiceRes?.items || []);

                  // Check if insurance is selected and calculate its cost
                  if (unitValue.selectedInsurance) {
                    let insuranceTaxRate = 0;
                    if (propData?.propertyTax?.length > 0) {
                      // Find the insurance tax rate based on type
                      const taxItem = await propData.propertyTax.find(item => item.type == 'insurance');
                      insuranceTaxRate = taxItem?.tax_rate || 0;
                    }

                    // Call unitServiceLineItem for insurance and add to the grand total
                    let unitIncRes = await this.unitServiceLineItem([unitValue.selectedInsurance], insuranceTaxRate, 'insurance');
                    unitTotalCost = unitTotalCost + (unitIncRes?.totalServiceCostIncludingGST || 0);
                    serviceItems = await serviceItems.concat(unitIncRes?.items || []);
                  }

                  // Add selected fee to serviceItemsPayload
                  if (unitValue?.selectedFees?.length > 0) {
                    let feeTaxRate = 0;
                    if (propData?.propertyTax?.length > 0) {
                      // Find the merchandise tax rate based on type
                      const taxItem = await propData.propertyTax.find(item => item.type == 'fee');
                      feeTaxRate = taxItem?.tax_rate || 0;
                    }

                    // Call unitServiceLineItem for merchandise and add to the grand total
                    let unitFeeRes = await this.unitServiceLineItem(unitValue.selectedFees, feeTaxRate, 'fee');
                    unitTotalCost = unitTotalCost + (unitFeeRes?.totalServiceCostIncludingGST || 0);
                    serviceItems = await serviceItems.concat(unitFeeRes?.items || []);
                  }

                  // Add selected merchandise to serviceItemsPayload
                  if (unitValue?.selectedMerchandise?.length > 0) {
                    let merchandiseTaxRate = 0;
                    if (propData?.propertyTax?.length > 0) {
                      // Find the merchandise tax rate based on type
                      const taxItem = await propData.propertyTax.find(item => item.type == 'merchandise');
                      merchandiseTaxRate = taxItem?.tax_rate || 0;
                    }

                    // Call unitServiceLineItem for merchandise and add to the grand total
                    let unitMerRes = await this.unitServiceLineItem(unitValue.selectedMerchandise, merchandiseTaxRate, 'merchandise');
                    unitTotalCost = unitTotalCost + (unitMerRes?.totalServiceCostIncludingGST || 0);
                    serviceItems = await serviceItems.concat(unitMerRes?.items || []);
                  }

                  // calculateDiscount and Promotions value
                  let discountAndPromotionItems = [];
                  let unitTotalDiscountAmount = 0;
                  let discountAndPromotionPayloadItems = [];
                  if (unitValue?.selectedDiscount) {
                    await discountAndPromotionPayloadItems.push(unitValue.selectedDiscount);
                  }
                  if (unitValue?.selectedPromotions?.length > 0) {
                    await discountAndPromotionPayloadItems.push(...unitValue.selectedPromotions);
                  }

                  if (discountAndPromotionPayloadItems.length > 0) {
                    let disAndProRes = this.calculateTheDiscounts(discountAndPromotionPayloadItems, Number(unitValue?.unit_price || 0))
                    discountAndPromotionItems = disAndProRes?.promotionDetails || [];
                    unitTotalDiscountAmount = disAndProRes?.totalDiscount || 0;
                  }
                  let unitGrandTotal = unitTotalCost - unitTotalDiscountAmount;
                  // Return the unit details for this unit
                  propertyTotalCost += unitGrandTotal;
                  return {
                    unit_id: unitValue?.unit_id,
                    unit_number: unitValue?.unit_number,
                    unit_size: unitValue?.unit_size,
                    unit_type: unitValue?.unit_type,
                    unit_type_name: unit_type_name,
                    unit_amenities: unit_amenities,
                    unit_floor: unitValue?.unit_floor,
                    unit_price: Number(unitValue?.unit_price || 0),
                    serviceItems: serviceItems,
                    discountAndPromotionItems: discountAndPromotionItems,
                    unitTotalCost: unitTotalCost,
                    unitTotalDiscountAmount: unitTotalDiscountAmount,
                    unitGrandTotal: unitGrandTotal
                  };
                })
              );

              // Return the property data with unit information and other details
              return {
                id: propData?.property?.id,
                name: propData?.property?.name,
                number: propData?.property?.number,
                description: propData?.property?.description,
                phoneNumber: (propData?.property?.Phones?.length)?propData?.property?.Phones[0].phone:'',
                address: propData?.property?.Address?.address,
                address2: propData?.property?.Address?.address2,
                city: propData?.property?.Address?.city,
                state: propData?.property?.Address?.state,
                zip: propData?.property?.Address?.zip,
                country: propData?.property?.Address?.country,
                propertyTotalCost: propertyTotalCost,
                units: unitValues
              };
            })
          );

          // Return the final result
          return parsedObj;
        } catch (err) {
          console.log("Unble to generate Property units Object")
          this.handleButtonClickStatus("viewRentalQuoteButton", false);
        }

      } else {
        // If no units are selected, show an error message
        this.showMessageNotification({
          type: 'error',
          description: 'Please select at least one unit under property for quote generation'
        });
        this.handleButtonClickStatus("viewRentalQuoteButton", false);
      }
    },
    getFirstTwoAmenities(amenities) {
      if (!amenities) return '';
      return amenities.split(',').slice(0, 2).join(' | ');
    },
    formatUnitTypeString(unitType) {
      return unitType ? unitType.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) : '';
    },
    calculateRentPeriod() {
      const options = { month: 'short', day: 'numeric' };
      const currentDate = new Date();

      // Add 30 days to the current date
      const endDate = new Date();
      endDate.setDate(currentDate.getDate() + 30);

      // Format dates without the year
      const formattedStart = currentDate.toLocaleDateString('en-US', options);
      const formattedEnd = endDate.toLocaleDateString('en-US', options);
      return `(${formattedStart} - ${formattedEnd})`;
    },
    unitServiceLineItem(data, gstPer, type) {
      let totalServiceCostIncludingGST = 0;

      const items = data.map(item => {
        let unit_price = 0;
        let qty = 1;

        // If type is "insurance" or "twa", we use premium_value as price
        if (type == "insurance") {
          unit_price = Number(item.premium_value || 0);
        } else {
          // Otherwise, use price from the product
          unit_price = Number(item.price || 0);
          qty = Number(item.qty || 1); // Default qty to 1 if not specified
        }

        // Check taxable status and apply GST only if taxable is 1
        // const gstPercentage = item.taxable == 1 ? gstPer : 0;
        const gstPercentage = gstPer;
        const gstAmountPerUnit = (unit_price * gstPercentage) / 100;
        const totalCost = unit_price * qty;
        const totalGSTAmount = (totalCost * gstPercentage) / 100;
        const totalCostIncludeGST = totalCost + totalGSTAmount;

        // Add the total cost including GST to the overall total
        totalServiceCostIncludingGST += totalCostIncludeGST;
        let itemName = `${item.name} ${(type == "merchandise")?`(Quantity ${qty})`:""}`;
        return {
          name: (type == "unit")?`Monthly Rent ${this.calculateRentPeriod()}`:itemName,
          unit_price,
          qty,
          gstPercentage,
          gstAmountPerUnit,
          totalCost,
          totalGSTAmount,
          totalCostIncludeGST
        };
      });

      // Return both the items and the total service cost including GST
      return {
        items,
        totalServiceCostIncludingGST
      };
    },
    calculateTheDiscounts(data, unitPrice) {
      let totalDiscount = 0;
      let promotionDetails = [];
      // Loop through each promotion item
      data.forEach(promotion => {
        let discountAmount = 0;
        // Check if promotion type is 'dollar' or 'percent'
        if (promotion.type == 'dollar') {
          // For dollar-based promotions, subtract the value directly
          discountAmount = promotion.value;
        } else if (promotion.type == 'percent') {
          // For percentage-based promotions, calculate based on unitPrice
          discountAmount = (unitPrice * promotion.value) / 100;
        }else{
          discountAmount = promotion.value;
        }
        // Add the promotion name and discount to the details array
        promotionDetails.push({
          name: promotion.name,
          discount: discountAmount,
          description: promotion.description || '', // Add description if it exists
          assignedBy: promotion.assigned_by || '', // Add assigned_by if it exists
          assignedOn: promotion.assigned_on || '', // Add assigned_on if it exists
          eligibleTenants: promotion.eligible_tenants || [] // Add eligible_tenants if it exists
        });
        // Add the calculated discount to the totalDiscount
        totalDiscount += discountAmount;
      });
      // Optionally add an additional discount (discountPer)
      return { totalDiscount, promotionDetails };
    },
    async showRentalQuoteDialog() {
      this.handleButtonClickStatus("sendRentalQuoteButton", true)
      if (this.contact) {
        await this.requestObjectForSendQuotePDF();
        if (this.sendQuotePDFFile) {
          this.handleButtonClickStatus("sendRentalQuoteButton", false)
          this.showSendRentalQuoteDialog = true;
        }
      }
    },
    async requestObjectForSendQuotePDF() {
      this.sendQuotePDFFile = null;
      let cleanedPropUnitObject = await this.prepareSendQuotePDFGenObj();
      this.sendQuoteEmailData = cleanedPropUnitObject;
      let tenantAddresses = (this.contact?.Addresses?.length > 0) ? this.contact.Addresses[0].Address : null
      let fullName = [this.contact.first, this.contact.middle, this.contact.last].filter(Boolean).join(" ");
      let tenantInfo = {
        fullName: fullName,
        address: tenantAddresses
      }
      const format = "pdf";
      const timeZone = moment.tz.guess();
      const requestData = {
        type: "send-quote-summary",
        format,
        data: {
          tenantInfo: tenantInfo,
          propertyUnits: cleanedPropUnitObject
        },
        contact_id: this.contact?.id,
        timeZone,
        name: "Send Quote Summary"
      };
      try {
        const response = await api.post(this, `${api.REPORTS}download-pdf`, requestData);
        // Convert response data to Blob
        const buffer = new Uint8Array(response.data);
        const blob = new Blob([buffer], { type: "application/pdf" });
        const file = new File([blob], `Rental Quote - ${fullName}.pdf`, { 
        type: 'application/pdf'
    });
        this.sendQuotePDFFile = file;
      } catch (err) {
        this.sendQuotePDFFile = null;
      }
    }
  },
  computed: {
    currentLeadTab() {
      let activeLeads = this.contact.Leads.filter(lead => lead.status === 'active');
      const leadIndex = activeLeads.findIndex(lead => lead.id === this.selectedLeadId );
      return leadIndex !== -1 ? leadIndex : 0; // Default to the first tab if not found
    },
    ...mapGetters({
      payment: 'onBoardingStore/payment', // TODO Make array
      properties: 'propertiesStore/filtered',
      hours: 'onBoardingStore/hours',
      categories: 'unit_category_id',
      lease: 'onBoardingStore/lease',
      lead: 'onBoardingStore/lead',
      contact: 'onBoardingStore/contact',
      configuration_unit: 'onBoardingStore/configuration_unit',
      unit: 'onBoardingStore/unit',
      invoices: 'onBoardingStore/invoices',
      tenants: 'onBoardingStore/tenants',
      insurance: 'onBoardingStore/insurance',
      reservation: 'onBoardingStore/reservation',
      selectedProperties: 'navigationStore/selectedProperties',
      latestContactData:"onBoardingStore/latestContactData",
      hasPermission: 'authenticationStore/rolePermission',
      getFollowUpStatus:'onBoardingStore/getFollowUpStatus',
      getTaxExempt: "onBoardingStore/getTaxExempt",
      getTaxExemptDescription: "onBoardingStore/getTaxExemptDescription",
      getFollowUpStatus:'onBoardingStore/getFollowUpStatus',
      template: 'onBoardingStore/template',
      singleSpaceRentalExists: "onBoardingStore/getSingleSpaceRental",
      sendQuotePropertyUnits: 'sendQuoteStore/propertyUnits',
      getSettings: 'authenticationStore/getSettings',
    }),
    property: {
      get() { return this.$store.getters['onBoardingStore/property'] },
      set(val) { return this.$store.commit('onBoardingStore/setProperty', val) }
    },
    currentStepper(){
      return this.stepper;
    },
    isValidLead() {
      if (
        (this.contact.lead && this.contact.lead.property_id) ||
        !this.contact.first ||
        (!this.contact.last && !this.contact.rent_as_business) ||
        (!this.contact.email && (!this.contact.Phones || !this.contact.Phones[0].phone))
      ) {
        return false;
      }
      return true;
    },
    isValidTenant() {
      if (
        !this.contact.Addresses ||
        !this.contact.Addresses.length ||
        !this.contact.Addresses[0].Address.address ||
        // !this.contact.Addresses[0].Address.city ||
        // !this.contact.Addresses[0].Address.state ||
        !this.contact.Addresses[0].Address.zip
      ) {
        return false;
      }
      return true;
    },
    canProgress() {
      if (this.lease.decline_insurance?.decline_insurance || this.insurance.id) {
        return true;
      }
      return false;
    },
    showMultiSpaceRentalBanner() { 
        if(!this.getFeatureSettingValueByName(SETTINGS.ALLOW_MULTI_SPACE_RENTAL) && !Object.keys(this.template).length && !this.unit?.id) return null;
        const template = this.template[this.unit?.id];
        const multiSpaceRental = template?.multi_space_rental;

        if(!multiSpaceRental) return null;

        let leases = JSON.parse(JSON.stringify(this.contact.Leases));
        if(leases.length === 0) return null;

        console.log("this.singleSpaceRentalExists", this.singleSpaceRentalExists);
        console.log("multiSpaceRental", multiSpaceRental);

        if (this.singleSpaceRentalExists?.flag && multiSpaceRental) {
            return LEASE.MULTI_SPACE_RENTAL_BANNERS.CONVERSION_TO_MULTI_SPACE;
        } else if (!this.singleSpaceRentalExists?.flag && !multiSpaceRental) {
            return LEASE.MULTI_SPACE_RENTAL_BANNERS.CONVERSION_TO_SINGLE_SPACE;
        } else {
            return null;
        }
    },
    currentLeadTab() {
      let activeLeads = this.contact.Leads.filter(lead => lead.status === 'active');
      const leadIndex = activeLeads.findIndex(lead => lead.id === this.selectedLeadId );
      return leadIndex !== -1 ? leadIndex : 0; // Default to the first tab if not found
    },
  },
  watch: {
    selectedLeadId(newId) {
      // Update the currentLeadTab when selectedLeadId changes, considering only active leads
      let activeLeads = this.contact.Leads.filter(lead => lead.status === 'active');
      const leadIndex = activeLeads.findIndex(lead => lead.id === newId );
      this.currentLeadTab = leadIndex !== -1 ? leadIndex : 0; // Default to the first tab if not found
    },
    lead() {
      console.log("================================================== lead is changed",this.lead);
      this.activeLeadsList = this.latestContactData?.Leads.filter(lead=>lead.status === 'active');
      
    },
    'contact.id'(){
        this.$store.dispatch('onBoardingStore/getContactLatestData',{contact_id:this.contact.id})
      },
    stepper() {
      if (this.stepper >= 2) {
        this.unitComponentStatusFlag = true;
      } else {
        this.unitComponentStatusFlag = false;
      }
    },
    propertyId() {
      
    },
    "property.id"() {
      if (this.currentTab != "units" || this.activeTab != 0) {
        this.activeTab = 0;
        this.currentTab = "units";
      }
    },
    async configuration_unit()
    {
      // check for settings availability 
      let property_query=''
            if(this.property.id){
                property_query = `&property_id=${this.property.id}`
            }
      let response = await api.get(this.$app, api.SETTINGS + `apw-details?category=leadManagement${property_query}`)
      if(response?.settings[0]?.value == 1){ // apw is active here
        if(this.property?.id && this.configuration_unit?.id) // trigger handler for undefined scenario
        this.apwPlans = await api.get(this, api.APW_MANAGEMENT + `occupancy-based-plan/${this.property.id}/${this.configuration_unit.id}/instore_plan`)
        else this.apwPlans = {}
      }else{
        this.apwPlans = {}
      }
    },
  },
  beforeDestroy() {
    this.addLeadInteractionData({})
    this.actionFollowUpStatus(false)
    this.$store.commit("onBoardingStore/resetTenants");
    this.$store.commit("onBoardingStore/resetLease");
    this.$store.commit("onBoardingStore/resetInvoice");
    this.$store.commit("onBoardingStore/resetReservation");
    this.$store.commit("onBoardingStore/resetContact");
    this.$store.commit("onBoardingStore/resetUnit");
    this.$store.commit("onBoardingStore/resetProperty");
    this.$store.commit("onBoardingStore/resetConfigurationUnit");
    this.$store.commit("onBoardingStore/resetUnitsInArea");
    this.$store.commit("onBoardingStore/resetInitialCharges");
    this.$store.commit("onBoardingStore/setContactLatestData",{})
    EventBus.$off("open_lease_hb_model");
    EventBus.$off("FromUnitViewSetUnit");
    EventBus.$off("lead-follow-up-interaction");
    EventBus.$emit('refetchPromotionsRevMan')

    this.$emit("close");
    this.addLeadInteractionData({})
  },
  async mounted() {
    
  }
};
</script>

<style scoped>
/* Your CSS styles specific to LeadIntake component */
.lead-intake-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.lead-intake-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;
  overflow: hidden;
}

.lead-intake-left {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 5;
  max-width: 730px;
  min-width: 390px;
  overflow: hidden;
  height: 100%;
  box-shadow: 8px 0px 18px rgba(71, 84, 116, 0.2) !important;
  z-index: 202 !important;
}

.lead-intake-right {
  flex: 4;
  max-width: 570px;
  min-width: 390px;
  display: flex;
  overflow: hidden;
}

.lead-intake-right >>> .v-slide-group__wrapper{
    border-bottom: 1px solid #E1E6EA
  }

.new_lead {
  width: 90% !important;
}

.tab-items {
  /* display: flex; */
  flex: 1;
  overflow: auto;
  height: 100%;
  background: #F9FAFB;
  padding-bottom: 5%;
  /* width: -webkit-fill-available; */
}
.add-padding-overlay-active {
  padding-bottom: 150px;
}

.lead-intake-right >>> .v-window {
  height: calc(100% - 27px) !important;
}

.lease-total-overlay {
  position: absolute;
  bottom: 60px;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0;
  z-index: 300;
  box-shadow: 0px -4px 10px 3px rgba(71, 84, 116, 0.1);

}

.lease-total-overlay >>> .v-card {
  border-radius: 0;
  padding: 0px !important;
}


div >>> .v-navigation-drawer {
  width: 98% !important;
}

.unitComponentStatusFlag {
  opacity: 0.7;
  /* Example styling for a disabled component */
  pointer-events: none;
  /* Disable pointer events on the component */
  cursor: not-allowed;
}

.divider-alignment {
  margin-top: 16px !important;
}
.mini-profile-reset-button-view{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width:763px;
  min-width: 373px
}
.tooltip-container{
      width: 200px;
      /* height: 120px; */
      display: flex;
      /* padding-top: 1px;
      padding-right: 6px;
      padding-bottom: 1px;
      padding-left: 6px; */
      font-size: 12px;
}
</style>
