<template>
    <div class="invoices-view mx-2" style="padding: 10px; ">
        <v-row no-gutters style="1px solid #dfe3e8; margin-bottom: 0">
            <v-col cols="12" :sm="'12'" class="invoice_display">
                <v-row v-if="
                    !moveOutCalc.dueInvoices ||
                    (!moveOutCalc.dueInvoices.length)
                " class="invoice-row mb-4">
                    <v-col class="pt-12" style="height: 120px; text-align: center">
                        No open invoices found
                    </v-col>
                </v-row>

                <!-- open invoices  -->
                <v-row v-for="invoice in sortedInvoices" :key="'activeLeaseOpenInvoice_' + (invoice.id || 'temp')"
                    no-gutters class="invoice-row mb-4">
                    <v-col>
                        <!-- invoice number -->
                        <v-row v-if="invoice.number" no-gutters class="invoice-line-row">
                            <v-col>
                                <span class="caption">Invoice #{{ invoice.number }}</span>
                            </v-col>
                        </v-row>

                        <!-- invoice lines JUST RENT -->
                        <v-row class="invoice-line-row" v-for="line in invoice.InvoiceLines"
                            :key="'activeLeaseOpenInvoiceRentLine_' + line.id" no-gutters>
                            <template v-if="line.Product.default_type == 'rent'">
                                <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                                    <span class="caption font-regular"
                                        v-show="line.Service && line.Service.recurring">({{ line.start_date
                                            | formatSmallDate }} - {{ line.end_date | formatSmallDate }})</span>
                                </v-col>
                                <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                <v-col style="flex: 0 1 0%" class="align-right">{{ (line.cost * line.qty) |
                                    formatMoney }}</v-col>
                            </template>
                        </v-row>

                        <!-- other invoice lines -->
                        <v-row class="invoice-line-row" v-for="line in invoice.InvoiceLines"
                            :key="'activeLeaseOpenInvoiceLine_' + line.id" no-gutters>
                            <template v-if="line.Product.default_type != 'rent'">
                                <v-col style="flex: 1 0 50%">{{ line.Product.name }}
                                    <span class="caption font-regular"
                                        v-show="line.Service && line.Service.recurring">({{ line.start_date
                                            | formatSmallDate }} - {{ line.end_date | formatSmallDate }})</span>
                                    <a href="#" v-if="!line.id" class="text-decoration-none caption ml-2"
                                        @click.prevent="onChargeRemove(line)">Remove</a>
                                </v-col>
                                <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                <v-col style="flex: 0 1 0%" class="align-right">{{ (line.cost * line.qty) |
                                    formatMoney }}</v-col>
                            </template>
                        </v-row>

                        <!-- subtotal -->
                        <v-row class="invoice-line-row" no-gutters>
                            <v-col style="flex: 1 0 50%"><strong>Subtotal</strong></v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right"><strong>{{ invoice.sub_total |
                                formatMoney
                                    }}</strong></v-col>
                        </v-row>

                        <!-- discounts -->
                        <v-row class="invoice-line-row" no-gutters v-if="invoice.discounts">
                            <v-col style="flex: 1 0 50%">Discounts</v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">({{ invoice.discounts |
                                formatMoney }})</v-col>
                        </v-row>

                        <!-- tax -->
                        <v-row class="invoice-line-row" no-gutters>
                            <v-col style="flex: 1 0 50%">Tax</v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">{{
                                invoice.total_tax | formatMoney
                            }}</v-col>
                        </v-row>

                        <!-- previous payments -->
                        <v-row class="invoice-line-row" no-gutters v-if="invoice.total_payments">
                            <v-col style="flex: 1 0 50%">Previous Payments / Credits</v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right">({{ invoice.total_payments |
                                formatMoney }})</v-col>
                        </v-row>

                        <!-- total now -->
                        <v-row class="invoice-line-row" no-gutters>
                            <v-col style="flex: 1 0 50%"><strong>Total</strong></v-col>
                            <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                            <v-col style="flex: 0 1 0%" class="align-right"><strong>{{
                                invoice.balance | formatMoney
                                    }}</strong></v-col>
                        </v-row>


                    </v-col>
                </v-row>

                <v-divider class="my-2 pa-2"></v-divider>

                <v-row no-gutters pt-0 pb-1 px-5 class="d-flex flex-column">
                    <div class="mb-2">
                        <strong>Additional Charges</strong>
                    </div>
                    <v-col>
                        <hb-select large :items="getFees" @change="setFeeAmount" v-validate="'required|max:45'"
                            data-vv-as="Charges" placeholder="Select Charge" v-model="selectedCharge"
                            id="additional_charges" name="additional_charges"
                            :error="!!errors.collect('additional_charges').length > 0" :clearable="false" />

                        <v-row class="my-3" no-gutters align="center">
                            <v-col cols="auto" class="pr-4">
                                Set Fee Amount
                            </v-col>
                            <v-col cols="auto" class="pr-2">
                                <hb-text-field box medium v-model="feeAmount" v-validate="'required|max:45'"
                                    data-vv-scope="default" name="feeAmount"
                                    :error="errors.has('default.text-field-condensed-box-medium-prepend-inner-$')"
                                    placeholder="0" style="width: 150px" prepend-inner-icon="mdi-currency-usd">
                                </hb-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <hb-btn color="secondary" small @click="onChargeSelect">Add</hb-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-divider class="my-2 pa-2"></v-divider>

                <!-- total / balance due  -->
                <v-row no-gutters class="invoice-line-row">
                    <v-col style="flex: 1 0 50%">
                        <div class="d-flex justify-end align-center hb-font-header-3">
                            <div class="pr-4">Subtotal:</div>
                            <div class="font-weight-medium">{{ moveOutCalc.message?.amount_due || 0 | formatMoney }}
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <!-- <v-row no-gutters class="invoice-line-row">
                    <v-col style="flex: 1 0 50%">
                        <div class="d-flex justify-end align-center">
                            <div class="pr-4">Move-Out Credit:</div>
                            <div>$ ({{ moveOutCredit | formatMoney }})</div>
                        </div>
                        <div class="d-flex justify-end caption text-grey">
                            ({{ creditStartDate | formatDate }} - {{ creditEndDate | formatDate }})
                        </div>
                    </v-col>
                </v-row> -->

            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from '../../../../assets/api.js';
import CollectPayment from '../../CollectPayment.vue';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'InvoicesView',
    data() {
        return {
            fees: [],
            feeAmount: 0,
            additionalCharges: [],
            selectedCharge: {},
            moveOutCalc: {},
        }
    },
    components: {
        CollectPayment
    },
    props: [
        'property_id',
        'lease_id'
    ],
    computed: {
        getFees() {
            if (!this.fees || !this.fees.products) return [];

            return this.fees.products.map(product => ({
                text: product.name,
                value: product
            }));
        },
        sortedInvoices() {
            if (!this.moveOutCalc.dueInvoices) return [];
            return [...this.moveOutCalc.dueInvoices].sort((a, b) => {
                // If either invoice has no ID, move it to the end
                if (!a.id) return 1;
                if (!b.id) return -1;
                // Sort by ID in ascending order
                return a.id - b.id;
            });
        }
    },
    methods: {
        ...mapGetters({
            getMoveOutCalc: 'paymentsStore/getMoveOutCalc'
        }),
        ...mapMutations({
            setMoveOutCalc: 'paymentsStore/setMoveOutCalc',
            setAdditionalCharges: 'paymentsStore/setAdditionalCharges'
        }),
        setFeeAmount() {
            if (!this.selectedCharge) return;
            this.feeAmount = this.selectedCharge.price;
        },
        onChargeRemove(line) {

            // Filter based on matching the product name
            this.additionalCharges = this.additionalCharges.filter(charge => {
                return (charge.value?.name || charge.name) !== line.Product.name;
            });

            this.updateLease();
            this.setAdditionalCharges({additionalCharges: this.additionalCharges});
        },
        async onChargeSelect() {
            if (!this.selectedCharge) return;
            console.log("Selected Charge: ", this.selectedCharge);
            if (this.selectedCharge) {
                console.log("Additional Charges: ", this.feeAmount);
                this.selectedCharge.price = Number(this.feeAmount);
                this.additionalCharges.push(this.selectedCharge);
                await this.updateLease();
            }
        },
        async calculateMoveOutDate() {
            let date = moment().startOf("day").format("YYYY-MM-DD");
            let response = await api.post(this, api.LEASES + this.lease_id + '/calculate-move-out-balance', { moved_out: date });
            this.moveOutCalc = response;
        },

        async updateLease() {
            console.log("Additional Charges: ", this.additionalCharges);
            let date = moment().startOf("day").format("YYYY-MM-DD");
            let response = await api.post(this, api.LEASES + this.lease_id + '/calculate-move-out-balance', { moved_out: date, additional_charges: this.additionalCharges });
            console.log("Updated Lease: ", response);

            this.feeAmount = 0;
            this.selectedCharge = {};
            this.moveOutCalc = response;
            this.setMoveOutCalc({moveOutCalc: this.moveOutCalc});
            this.setAdditionalCharges({additionalCharges: this.additionalCharges});
        }
    },
    async mounted() {
        console.log('InvoicesView component mounted');
        this.moveOutCalc = await this.getMoveOutCalc();
        
        if (!this.moveOutCalc?.dueInvoices) {
            this.moveOutCalc = await this.calculateMoveOutDate();
            this.setMoveOutCalc({moveOutCalc: this.moveOutCalc});
        }
        this.setAdditionalCharges({additionalCharges: this.additionalCharges});
        
        try {
            const response = await api.get(this, api.PROPERTIES + this.property_id + '/products', {
                type: 'late',
            });
            this.fees = response;
        } catch (error) {
            console.error("Error fetching fees:", error);
        }
    }
}
</script>
