<template>
    <div style="background: #F9FAFB; display: flex; flex-direction: column; width: 100%; height: 100%; align-items: stretch; height: 100%;">
        <hb-blade-header
            :title="`${template.name} (${template.type})`"
            show-help-link
            back-button-off
            :left-align-title="true"
            :first-column-off="true"
            background="white"
            @close="$emit('close')"
        >

            <template v-slot:right>
                <hb-tooltip dashed class="mr-2">
                    <template v-slot:item>
                        {{ template.Properties.length}} Properties
                    </template>
                    <template v-slot:header>
                        Properties
                    </template>
                    <template v-slot:body>
                        <div v-for="p in template.Properties" :key="p.id">
                            <div>{{ getProperty(p).number }} {{ getProperty(p).name }}</div>
                        </div>
                    </template>
                </hb-tooltip>

                <!-- <HbStatusGeneral
                    class="ma-4"
                    :status="template.type"
                    type="guidance"
                    
                /> -->
                

                <!-- <hb-icon medium class="mr-2">mdi-account-clock-outline</hb-icon> -->


                <v-menu
                    close-on-click
                    close-on-content-click
                    offset-y
                    class="d-inline-block"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-inline-block">
                        <hb-btn icon active-state-off>
                            mdi-dots-vertical
                        </hb-btn>
                    </span>
                    </template>
                    <v-list class="menu-list other-document-actions">
                    <v-list-item :ripple="false" class="menu-list-item" @click="showEditForm = true" >
                        Edit
                    </v-list-item>
                    
                    </v-list>
                </v-menu>

     
            </template>

        </hb-blade-header>

        
        <div class="manage-template-container" style="display: flex; width: 100%; height: 100%; align-items: stretch; height: 100%; flex: 1 0; background-color: #F9FAFB">
            <div style="flex: 1 0; display: flex; flex-direction: column;">
                <div style="flex: 1 0;" v-if="template_is_loaded">
                    <Editor ref="editor" :template="template.template"  document_type="document" @showPreview="showPreview" @showMergeFields="showMergeFields" />
                </div>
                <div v-else-if="template_is_loading">
                    loading
                </div>
            </div>

            <TemplateForm  v-if="showEditForm" title="Manage Template" :selected="template" @save="saveTemplate" @close="closeForm()" />
            
            
            <div v-if="panel" style="flex: 0 1 360px; display: flex; flex-direction: column;">
                
               
            
               
                <MergeFields v-if="panel == 'merge_fields'" @addMergeField="addMergeField" />   
            

                <!-- <div v-if="panel == 'preview'">
                    <div v-html="preview_content"></div>   
                </div> -->


            </div>



        </div>

        <HbBottomActionBar>
             <template v-slot:right-actions>
                <hb-btn @click="saveTemplate()">Save Template</hb-btn>
            </template>
            <hb-btn>Save</hb-btn>
        </HbBottomActionBar>

    </div>
</template>


<script type="text/babel">
import MergeFields from './MergeFields.vue';
import TemplateForm from './TemplateForm.vue';
import Editor from './MceEditor.vue';
import api from '../../../assets/api.js';
 import { mapGetters } from "vuex";
import HbStatus from '../../../aviary/HbStatus.vue';
export default {
    name: "ManageDocumentTemplate",
    data() {
        return {
            
            showEditForm: false,
            activeTab: 'settings', 
            template: {
                id: null, 
                carboneTemplateId: null, 
                name: '',
                type: '',
                Properties: [],
                template: '',
            },
            preview_content: '',
            template_is_loaded: false, 
            template_is_loading: false, 
            expansion_panels: 0,
            panel: null, 
            template_types: [
                { text: 'Rent Change', value: 'rent-change' },
                { text: 'Delinquency', value: 'delinquency' },
                { text: 'Maintenance', value: 'maintenance' },
                { text: 'Alerts', value: 'alerts' },
                { text: 'General', value: 'general' },
            ],
        };
    },
    props: ['data'],
    async created(){
        // await this.getDocumentTypes();
            
        if(this.$props.data?.id){
            this.template.id = this.$props.data.id;
            this.fetchTemplate();
        } else {
            this.showEditForm = true;
            this.template_is_loaded = true;
            this.template_is_loading = false;
        }
    }, 
    computed:{
        ...mapGetters({
            facilityList: 'propertiesStore/filtered',
        }),
        filterPropertyList() {
            return this.facilityList.map(obj => ({ id: obj.id, name: obj.name }));
        },
         isEditDocument(){
            return !!this.template.id
        }
    },
    methods:{
        closeForm(){
            if(!this.template.id) {
                this.$emit('close')
            }
        }, 
        showPreview(content){

            if(this.panel == 'preview'){
                this.panel = null;
                this.preview_content = null
            } else {
                this.panel = 'preview';
                this.preview_content = content

            }
        }, 

        getProperty(p){
            let property = this.facilityList.find(prop => prop.id === p.id )
            if(!property) return {}
            return property
        }, 


        showMergeFields() {

            this.panel = this.panel == 'merge_fields' ? null : 'merge_fields';
        }, 
        async addMergeField(token){
            this.$refs.editor.insertToken(token)
        }, 
        async getDocumentTypes(){
            try {
                let response = await this.$http.get(api.DOCUMENT + '/types');
                this.template_types = response.body.data.types;
            } catch(err){
                console.log(err)
            }
        }, 
        async fetchTemplate(){
            try {
                
                this.template.template = {}; 
                this.template_is_loading = true;
                let response = await this.$http.get(api.DOCUMENT + '/' + this.template.id)
                
                this.template.template = response.body.data.template.template || "";
                this.template.name = response.body.data.template.name;
                this.template.Properties = response.body.data.template.Properties;
                this.template.type =response.body.data.template.type;
                console.log("this.template", this.template); 
            } catch(err){
                console.log(err); 
            }
            this.template_is_loaded = true;
            this.template_is_loading = false;
        }, 

  
        getPreview(){
            
            let template = this.$refs.editor.getContent(); 
            this.template.template = template;
            this.template.is_signature = is_signature;

        },
        async saveTemplate(template_data){
    
            let should_exit = false;   
            let data = {};
            if(template_data){
                // If data is passed in, its coming from the edit details modal. 
                // Dont update the template html here, since the user isnt expecting that to be saved
                data.template = this.template.template || "";
                data.name = template_data.name;
                data.Properties = template_data.Properties;
                data.type = template_data.type;

            } else {
                // else, save the editor content and then exit the editor
                should_exit = true;
                data = {
                    template: this.$refs.editor.getContent(),
                    name: this.template.name,
                    Properties: this.template.Properties,
                    type: this.template.type,
                }
            }
            
            if(this.template.id){
                let response = await this.$http.put(`${api.DOCUMENT}/${this.template.id}`, data)
            } else {
                let response = await this.$http.post(api.DOCUMENT, data)
                console.log("response", response)
                this.template.id = response.body.data.document_id
            }
            this.fetchTemplate();
            this.$emit('onDocumentsUpdated')
            if(should_exit){
                this.$emit('close')
            }
            this.showEditForm = false;
        }
    }, 
    components:{
        MergeFields,
        Editor,
        TemplateForm,
        HbStatus
    }




}
</script>